const CarouselData = [
  {
    id: 1,
    title: "Measure UP",
    description: `your weight, height and BMI 
        at once, ”MeasureUP” is a revolutionary 
        device that allows you to quickly and
        accurately measure your weight,
        height, and BMI in just seconds.`,
    image: require("./../Resources/MeasureUP png_1.jpg").default,
  },
  {
    id: 2,
    title: `Boost UP`,
    description:
      "",
    image: require("./../Resources/MeasureUP png_2.jpg").default,
  },
  {
    id: 3,
    title: `Time’s UP`,
    description: `to those time consuming and tedious BMI calculations.
     Let MeasureUP handle it for you, freeing up your
      valuable time for more important tasks.`,
    image: require("./../Resources/MeasureUP png_3.png").default,
  },
];

export default CarouselData;
