const CarouselData2 = [
  {
    id: 1,
    title: "Levelup",
    description: `to a whole new level of futuristic design
        with futuristic technology`,
    image: require("./../Resources/MeasureUP png_1.jpg").default,
  },
  {
    id: 2,
    title: `Take UP`,
    description: 'to your backpack as its truly portable with its small size <br/> Height device   L X B X H <br/> Weight device  L X B X H',
    image: require("./../Resources/MeasureUP png_2.jpg").default,
  },
  {
    id: 3,
    title: `Lineup`,
    description: `with your favorite shade.`,
    image: require("./../Resources/MeasureUP png_3.png").default,
  },
];

export default CarouselData2;
